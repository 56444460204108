/* App.css */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* outline: 1px solid red; */
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: rgb(242, 239, 239);
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  flex: 1;
  text-align: center;
}
/* // CSS for ImageSlider */
.Image-Slider {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75em;
  height: 30em;
}
.current-image {
  box-shadow: 0px 0px 7px rgb(12, 12, 12);
  width: 100%;
  height: 100%;
}
.arrow {
  position: absolute;
  width: 2.9%;
  height: 6%;
  background-color: rgb(16, 16, 16);
  border-radius: 55%;
  color: rgba(207, 202, 202, 0.668);
  filter: drop-shadow(0px 0px 5px grey);
}
.arrow-left {
  left: 1rem;
}
.arrow-right {
  right: 1rem;
}

.circle-indicator {
  display: flex;
  position: absolute;
  bottom: 1rem;
}
.current-indicator {
  background-color: rgba(249, 243, 243, 0.685);
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: none;
  outline: none;
  margin: 0 0.2rem;
  cursor: pointer;
}
.hide-current-image {
  display: none;
}
.hide-current-indicator {
  color: black;
}
/* The container and the main-content class make 
sure the footer stays at the bottom at all times App.jsx*/
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

/* // CSS for  Footer */
.footer {
  position: relative; /* Change to relative to allow scrolling */
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 20px 0;
  background-color: #1b1a1a;
  color: #f1f1f1;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.footer-section {
  flex: 1 1 250px;
  margin: 10px;
}

.footer-section h3 {
  color: #f10511;
  border-bottom: 2px solid #baba60;
  padding-bottom: 10px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin: 10px 0;
}

.social-media {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.social-media h4 {
  flex-basis: 100%;
  margin-right: 10px;
}

.social-icon {
  display: inline-block;
  height: 24px;
  background-size: cover;
  color: white;
}

.footer-bottom {
  width: 100%;
  text-align: center;
  margin-top: 5px;
  border-top: 1px solid #baba60;
  padding-top: 10px;
}

.footer-bottom a {
  color: #ed0404;
  margin: 0 5px;
  text-decoration: none;
}

.footer-bottom a:hover {
  text-decoration: underline;
}

.get-in-touch {
  color: #f10511;
  font-weight: 700;
  margin-top: 10px;
}

.icon-rounded-info {
  background-color: white;
  border-radius: 15px;
}

.text-align-start {
  text-align: start;
}

.white {
  color: white;
}

.p-margin {
  margin-top: 10px;
}

.p-margin p {
  margin-bottom: 4px;
}

.margin-top-10 {
  margin-top: 8px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
  }

  .footer-section {
    flex: 1 1 100%;
    margin: 5px 0;
  }

  .social-media {
    flex-direction: row;
    align-items: flex-start;
  }

  .social-media h4 {
    margin-bottom: 10px;
  }

  .padding-left-3 {
    padding-left: 3%;
  }
}

.logo-download {
  height: 400px;
}

.campus-photo {
  height: 500px;
  width: 100%;
}
