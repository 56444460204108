.university-in-figures {
    text-align: center;
    padding: 20px;
  }
  
  .figures-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .figure-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid #b45110;
    border-radius: 50px;
    padding: 20px;
    width: 150px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .figure-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
 
  .figure-text {
    color: #b45110;
  }
  
  .figure-text p {
    margin: 5px 0;
  }
  
  .university-figures-icon {
    font-size: 45px !important;
    margin-top: -44px !important;
    margin-bottom: 44px !important;
    background-color: white !important;
  }