.content-layout-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;
  padding: 20px;
}

.sidebar {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid black;
}

.sidebar h2 {
  margin-bottom: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  cursor: pointer;
  padding: 10px;
  white-space: nowrap;
  border-top: 1px solid black;
}

.sidebar ul li:last-child {
  border-bottom: 1px solid black;
}

.sidebar ul li:hover {
  box-shadow: 0px 1px 1px 1px #b0adad;
  border-bottom: 1px solid #e4e4e4;
}

.sidebar ul li a {
  text-decoration: none;
  color: black;
}

.content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.content h2 {
  margin-bottom: 20px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.image-grid img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.content p {
  margin-bottom: 10px;
  line-height: 1.6;
}

/* Mobile styles */
@media (max-width: 768px) {
  .content-layout-container {
    grid-template-columns: 1fr;
  }

  .sidebar {
    display: none;
  }

  .sidebar.visible {
    display: block;
  }

  .sidebar-toggle {
    display: block;
    margin-bottom: 20px;
    cursor: pointer;
  }
}
