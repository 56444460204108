.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
  border-bottom: 1px solid #ddd;
}

.header-left {
  display: flex;
  align-items: center;
  width: 20%;
}

.logo {
  height: 100px;
}

.university-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
}
.header-right {
  width: 20%;
}

.university-details h1 {
  font-size: 24px;
  margin: 0;
}

.university-details p {
  margin: 2px 0;
  font-size: 18px;
  font-weight: 530;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.badge {
  margin-left: 15px;
  padding: 5px 10px;
  background-color: red;
  color: white;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-left,
  .header-right {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .university-details {
    width: 100%;
    margin-left: 0;
    align-items: flex-start;
  }

  .university-details h1 {
    font-size: large;
  }

  .university-details p {
    font-size: medium;
  }
}
