.menu-item {
  position: relative;
  cursor: pointer;
  /* font-weight: bold; */
  color: rgb(241, 234, 234);
  text-decoration: none;
  padding-top: 1.1%;
}

.menu-item:hover {
  background-color: #b30e0e;
  color: rgb(22, 21, 21);
}

.dropdown {
  position: absolute;
  top: 150%;
  left: -8.5%;
  background-color: rgb(241, 234, 234) ;
  color: black !important;
  border: 1px solid #0e0e0e;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-item {
  padding: 10px;
  white-space: nowrap;
  border-bottom: 1px solid #1e1c1c;
  background-color: white;
  color: black !important;
}

.dropdown-item:hover {
  background-color: white ;
  color: white;
}

.black {
  color: black !important;

}

/* dropdown.css */

.sub-dropdown {
  position: absolute;
  left: 100%;
  top: 0;
  display: block;
  background-color: white;
  border: 1px solid #100f0f;
  z-index: 1001;
}

.sub-dropdown-item {
  padding: 10px;
  white-space: nowrap;
  border-bottom: 1px solid #1e1c1c;
}
