.navbar {
  display: flex;
  justify-content: space-around;
  background-color: #b30e0e;
  padding: 10px;
  flex-wrap: wrap;
}

.navbar a {
  color: rgb(241, 234, 234);
  text-decoration: none;
  padding: 10px;
}

/* .navbar a:hover {
  background-color: rgb(134, 20, 20);
  color: rgb(241, 234, 234);
} */

/* Add styles for hamburger menu */
.hamburger-menu {
  display: none;
}

.hamburger-menu .line {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px 0;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
  }

  .navbar a {
    background-color: white;
    border-radius: 21px;
    color: black;
    margin-top: 1%;
    font-weight: bold;
  }

  .navbar-item:first-child {
    border-top: none;
  }

  .hamburger-menu {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  .navbar-links {
    display: none; /* Hide navbar links by default on smaller screens */
  }

  .navbar-links.active {
    display: flex; /* Show navbar links when active */
    text-align: center;
    align-items: center;
    width: 100%;
  }
}
